import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Row, Col } from "react-bootstrap"
import { v4 as uuidv4 } from "uuid"

import { Section, LinkButton } from "../../UI/Common"

import {
  boxWrapperCls,
  bkgdImgCls,
  btnWrapperCls,
} from "./TwoImagesStripWithBtns.module.scss"

const TwoImagesStripWithBtns = ({ imageBoxes }) => {
  return (
    <Section>
      <Row>
        {imageBoxes.map((box, i) => {
          const {
            button,
            backgroundImg: { mobile: mobileBkgdImg, desktop: desktopBkgdImg },
          } = box

          let bkgdImgStyles = bkgdImgCls

          return (
            <Col className="px-0" xs={12} lg={6} key={uuidv4()}>
              <div className={boxWrapperCls}>
                <GatsbyImage
                  className={`d-lg-none ${bkgdImgStyles}`}
                  style={{ position: "absolute" }}
                  alt={mobileBkgdImg.altText}
                  image={getImage(mobileBkgdImg.localFile)}
                />
                <GatsbyImage
                  className={`d-none d-lg-block ${bkgdImgStyles}`}
                  style={{ position: "absolute" }}
                  alt={desktopBkgdImg.altText}
                  image={getImage(desktopBkgdImg.localFile)}
                />
                <div className={btnWrapperCls}>
                  <LinkButton
                    link={button?.url}
                    title={button?.title}
                    target={button?.target}
                    variant="secondary"
                  />
                </div>
              </div>
            </Col>
          )
        })}
      </Row>
    </Section>
  )
}

export default TwoImagesStripWithBtns
