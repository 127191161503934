import { graphql } from "gatsby"
import React from "react"

import TwoImagesStripWithBtnsComponent from "./TwoImagesStripWithBtns"

export const fragment = graphql`
  fragment TwoImagesStripWithBtnsFragment on WpPage_Flexlayouts_FlexibleLayouts_TwoImagesStripWithBtns {
    imageBoxes {
      button {
        url
        target
        title
      }
      backgroundImg {
        desktop {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                width: 1073
                placeholder: NONE
              )
            }
          }
        }
        mobile {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                width: 937
                placeholder: NONE
              )
            }
          }
        }
      }
    }
  }
`

export const ACFTwoImagesStripWithBtns = ({ imageBoxes }) => (
  <TwoImagesStripWithBtnsComponent imageBoxes={imageBoxes} />
)
