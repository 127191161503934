// extracted by mini-css-extract-plugin
export var bgGrey100 = "TwoImagesStripWithBtns-module--bg-grey-100--bc072";
export var bgGrey150 = "TwoImagesStripWithBtns-module--bg-grey-150--9bc87";
export var bgGrey200 = "TwoImagesStripWithBtns-module--bg-grey-200--10344";
export var bgGrey300 = "TwoImagesStripWithBtns-module--bg-grey-300--0e9c2";
export var bgGrey400 = "TwoImagesStripWithBtns-module--bg-grey-400--e4dd7";
export var bgGrey500 = "TwoImagesStripWithBtns-module--bg-grey-500--444a2";
export var bgGrey600 = "TwoImagesStripWithBtns-module--bg-grey-600--f72f9";
export var bgGrey700 = "TwoImagesStripWithBtns-module--bg-grey-700--8faeb";
export var bgGrey800 = "TwoImagesStripWithBtns-module--bg-grey-800--6f0b2";
export var bgGrey900 = "TwoImagesStripWithBtns-module--bg-grey-900--688f7";
export var bkgdImgCls = "TwoImagesStripWithBtns-module--bkgdImgCls--a0bba";
export var boxWrapperCls = "TwoImagesStripWithBtns-module--boxWrapperCls--38cc2";
export var btnWrapperCls = "TwoImagesStripWithBtns-module--btnWrapperCls--bafb8";
export var textGrey100 = "TwoImagesStripWithBtns-module--text-grey-100--49fcf";
export var textGrey150 = "TwoImagesStripWithBtns-module--text-grey-150--650b8";
export var textGrey200 = "TwoImagesStripWithBtns-module--text-grey-200--901e4";
export var textGrey300 = "TwoImagesStripWithBtns-module--text-grey-300--3e2d8";
export var textGrey400 = "TwoImagesStripWithBtns-module--text-grey-400--a39af";
export var textGrey500 = "TwoImagesStripWithBtns-module--text-grey-500--cf777";
export var textGrey600 = "TwoImagesStripWithBtns-module--text-grey-600--9552e";
export var textGrey700 = "TwoImagesStripWithBtns-module--text-grey-700--48f31";
export var textGrey800 = "TwoImagesStripWithBtns-module--text-grey-800--87606";
export var textGrey900 = "TwoImagesStripWithBtns-module--text-grey-900--2c966";